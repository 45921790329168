<template>
  <div class="wrapper">
    <div class="tips" v-if="!$store.state.userInfo">
      <div class="login" @click="auth(2)">{{ $t('lang.login') }}</div>
    </div>
    <div class="user" v-else @click="toShowMoney">
      <div class="avatar"></div>
      <div class="info">
        <div class="name">{{$store.state.userInfo.name}}</div>
        <div class="id">MetaID: {{$store.state.userInfo.metaId.substr(0, 6)}}</div>
      </div>
    </div>
    <div class="list">
      <div class="item" @click="toShowMoney"  v-if="$store.state.userInfo">
        我的钱包
      </div>
      <div class="item">
        关于应用
      </div>
    </div>
    <div class="list">
      <div class="exit" v-if="$store.state.userInfo" @click="exit">
        退出登录
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "User",
  methods: {
    auth(appType) {
      if (this.$store.state.userInfoLoading) return
      this.$store.state.sdk?.changeSdkType(appType)
      this.$store.state.sdk?.login()
    },
    exit() {
      this.$store.commit('LOGOUT')
    },
    toShowMoney() {
      location.href = process.env.VUE_APP_AuthUrl
    }
  }
}
</script>

<style lang="less" scoped>
  .wrapper {
    background-color: #F5F7FA;
    position: absolute;
    /*height: 100%;
    width: 100%;*/
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .tips {
      height: 120px;
      background-color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      .login {
        background-color: #617FFF;
        width: 90px;
        height: 30px;
        border-radius: 20px;
        font-size: 12px;
        color: #FFFFFF;
        text-align: center;
        line-height: 30px;
        //margin: 0 auto;
      }
    }
    .user {
      height: 120px;
      background-color: #FFFFFF;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      .avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #CCCCCC;
      }
      .info {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-left: 10px;
        .name {
          color: #303133;
          font-size: 14px;
        }
        .id {
          color: #bfc2cc;
          font-size: 12px;
          margin-top: 7px;
        }
      }
    }
    .list {
      margin-top: 20px;
      .item {
        height: 50px;
        background-color: #FFFFFF;
        line-height: 50px;
        font-size: 14px;
        font-weight: 600;
        color: #303133;
        padding: 0px 20px;
      }
      .exit {
        height: 50px;
        background-color: #FFFFFF;
        line-height: 50px;
        font-size: 14px;
        font-weight: 600;
        color: #303133;
        padding: 0px 20px;
        text-align: center;
        font-size: 14px;
        color: #f8423b;
      }
    }
  }
</style>
