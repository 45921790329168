var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    !_vm.$store.state.userInfo
      ? _c("div", { staticClass: "tips" }, [
          _c(
            "div",
            {
              staticClass: "login",
              on: {
                click: function ($event) {
                  return _vm.auth(2)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang.login")))]
          ),
        ])
      : _c("div", { staticClass: "user", on: { click: _vm.toShowMoney } }, [
          _c("div", { staticClass: "avatar" }),
          _c("div", { staticClass: "info" }, [
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.$store.state.userInfo.name)),
            ]),
            _c("div", { staticClass: "id" }, [
              _vm._v(
                "MetaID: " +
                  _vm._s(_vm.$store.state.userInfo.metaId.substr(0, 6))
              ),
            ]),
          ]),
        ]),
    _c("div", { staticClass: "list" }, [
      _vm.$store.state.userInfo
        ? _c("div", { staticClass: "item", on: { click: _vm.toShowMoney } }, [
            _vm._v(" 我的钱包 "),
          ])
        : _vm._e(),
      _c("div", { staticClass: "item" }, [_vm._v(" 关于应用 ")]),
    ]),
    _c("div", { staticClass: "list" }, [
      _vm.$store.state.userInfo
        ? _c("div", { staticClass: "exit", on: { click: _vm.exit } }, [
            _vm._v(" 退出登录 "),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }